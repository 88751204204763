import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Getting Started with Android Android Development - Android Studio Edition Pt 3",
  "date": "2014-07-12T22:41:35-05:00",
  "tags": ["android", "android-studio"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`This post is part of the Getting Started with Android Application Development - Android Studio Edition. In the `}<a parentName="p" {...{
        "href": "http://blog.adrianchia.com/getting-started-with-android-development/"
      }}>{`previous post`}</a>{` we discussed how to setup our first Android Virtual Device (AVD). In this post we are going to create our first application using Android Studio.`}</p>
    <h2>{`First Application Development`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Open Android Studio and click on `}<em parentName="p">{`New project…`}</em>{`
`}<span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "590px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/9d0edbff0a0b614ee19ce4847a1b3759/160a3/01.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "75%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACY0lEQVQoz32Ry27TQBSG/WqFAhILJJ4AIXgHFt0UIRACVBASi27YsKAsECukSrRuSZU6ie93e8aecZzY44kdj2k2TZrWyOmFUio+/YuzmE//mRnuycbe6hdr5bP64KN174Nx9716551ye02+9UZcftW7+bJz4/n+0rP20tPW0urO8ip//wX/cI1//Hb70etNrmdY37V1weZdD1mO53jgLD60XWC7zWw5vu0B2/UdDzp+EwADQXG4PYPfkFZ465OmKD93d0SxJ/a6vW5XVWRBEIT9tij2Op2O0G53BMHzPYTCIAgiFMq6yxkulMGWj8wI9yGEMLhKGIYY44sZIRQi1I+wbPqcCZCpmpKo6IbeHIIQgjP8BaqqSpLke83snQN8TzIBlyTpZHJQVdXBZJKPqGa7ludHGCGE4gUYY4RwfIl+HCfDWHMxV+VZXdf1yUld11VVGabpOGYYNOtROqKUjhbQS2SUFvnIhDFXLuTj+Xx+fPyLsZahdR17gKMgDNOUZNdBsiwfURPE3Ligs9ns8PBwOp2ykimmbtgG8AGEwVnPf+SyHNfnMMYc2wqA2+/HURQlSTIcDpMkvV6GMUfGrLnyQq5YaYd9EEZhEJz+GgQAY3yln5Asp5kJBxwt/8iMVcA1oKsjhElKiiI/faqrzYTQLG3kglUXa1eMaZat2l4AgOf5URQR0vSQ7K8khBCSGnDIjdm4nh8dzabT6awoihDhKE5JmpFkQNNhQcm/yTNS5sQNB9w30dwUtZasG7qmapptW5bVFdVdXnV3DcRrAa8G28pp4JYMtyTwQwQ7Mvjacn8DQc4A10EVzT8AAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "01",
                "title": "01",
                "src": "/static/9d0edbff0a0b614ee19ce4847a1b3759/fcda8/01.png",
                "srcSet": ["/static/9d0edbff0a0b614ee19ce4847a1b3759/12f09/01.png 148w", "/static/9d0edbff0a0b614ee19ce4847a1b3759/e4a3f/01.png 295w", "/static/9d0edbff0a0b614ee19ce4847a1b3759/fcda8/01.png 590w", "/static/9d0edbff0a0b614ee19ce4847a1b3759/160a3/01.png 682w"],
                "sizes": "(max-width: 590px) 100vw, 590px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Give your Application a name, ths name is what we will see on the phone as well as on Google Play Store if you published it at the end of the development.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`We will leave the rest of the configuration on this page as is for now and click `}<em parentName="p">{`Next`}</em>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select the type of Android Devices we are going to build against. (e.g. Phone/Tablet, TV, Wear or Glass). We will select Phone/Tablet for now and click `}<em parentName="p">{`Next`}</em>{`
`}<span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "590px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/7a1b0ecb653ef4c67f0eceea0bf40a17/302a4/06.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "60.13513513513513%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACGUlEQVQoz3XQTU8TQRgH8P0sJtCzicaTHwCKB29ewdUY1GpiOEiQYKPxAyh6MVRbK0jQkFBaia9o1AC10MjL7M7ubGdn5pmZXYq02OiJmN3SaEL4zy+TzOGf58kYo/n0WD5t3h85e2/ozJ1rB9KpvvTV5O0rybHLvaODvbcu9Y5c7Bk2e26eTw4PnLs7OPToempkwBjMn0o9P3Fj8nTq2XFzoutCptuc6Pon02VmujsS5pOEmUn0P+7uf3qsL33SmFuaml/JFpazc0vZwnJubjn3ujy18H36SOXp0sqLTxuvHkyOG7jmg+QcOAemtQ6D7Z2w0ai3jtLcaf0M9/Z/75feLBmWxwKtBAjP8wghQgilpFTQprSUWqoISCVAgs8F46zZqE8X3hqOLwKtlFLMZ65L0BZaW41TWV1f38A2tizbQshCjm1Tz6ttYsoF/Gruvpz/YLhxWUrlU5/5zPM828YIWRhjpx3sYNt2sOc6rFajgnMBcbn40XAZtCdTSmmNCgEgZbz0/1HxDQDAuRDiUJkQQlzCGPMZp4z7jB+KiHUmlxYNh4HWSsaT3ajMWfQlEZ8LLkDAAYgWAAnAhdxr7M4UFw0q5E6ow0DXw6AeBtGJbUdP3RFsBzrUknEFoLSCP63G7MJnYwXzdU9UCV9z+JpDLYJcilxvs2qTMvLLiJa3aBnRL1X87lvl4eyPXLH6/mulsumO50t/Aa5eNxsAD0JEAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "06",
                "title": "06",
                "src": "/static/7a1b0ecb653ef4c67f0eceea0bf40a17/fcda8/06.png",
                "srcSet": ["/static/7a1b0ecb653ef4c67f0eceea0bf40a17/12f09/06.png 148w", "/static/7a1b0ecb653ef4c67f0eceea0bf40a17/e4a3f/06.png 295w", "/static/7a1b0ecb653ef4c67f0eceea0bf40a17/fcda8/06.png 590w", "/static/7a1b0ecb653ef4c67f0eceea0bf40a17/efc66/06.png 885w", "/static/7a1b0ecb653ef4c67f0eceea0bf40a17/302a4/06.png 1080w"],
                "sizes": "(max-width: 590px) 100vw, 590px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add an Activity to your application. An activity is an Android components that display the screen and/or interact with the application users. see `}<a parentName="p" {...{
            "href": "https://developer.android.com/guide/components/activities.html"
          }}>{`here`}</a>{` for more details. We will add a Blank Activity and click `}<em parentName="p">{`Next`}</em>{`.
`}<span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "590px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "60.13513513513513%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACc0lEQVQozz2R204TURSG59IX8HW89tJLQ2IMia/AGxhQ4MJEvTCoiYpiUw6hQkoLSAy0YKFyLO3QlpbpYfbM7DntPbNnz2kvU0hY+W5X1vf/S/qwUV3ekz9tNWbX5FsaM78aM7n69Orl9Ert9fL5q8XTqezx1M/q1EJlcv5gcr40u1B+t3TwcXVfqpzJSrvRbTX67TrFKnO0W/R7AldnrkEdw3eMgGDmYt/Fjq1jS5eqcs/Q9aGqqUhDmm6Ylmk7mo6RbliWbdkO0gxsYO5YxMQq0g2MCXFMy1IRkmTVBQAhBACkaXp0dJTNZjHGSZJsbG6t5NYgSa6xM1E8+3JlAUCro7yd+35yeh6GXGrqBEBwHqZpKoRIkoRzniZJlArKI8vnALCNgodLvfHdwWhZocVyk+Ku5wdSGxMQgjGWJMnd/buJUhEJYHEKAL+bgwcTcy+KMgAgBZ8eVwUbUi+QOha512aMmaZpGIbrunGcxHHs+z4AuI69VSxc9hEAhGFommYcRZRSqef6ak/J59avO10AKJVKmUxG03XHtmudftOkJBYIoa/f5quHFQBQbpRMJlOv16MokgauZ/jennIYBhQACCHDoQoAP1bXH0/OLfa8HTPZLmw8HRtLR36Cc44xDgIehqE0dKkK6fpODog98k9Tx3EAYHMt9+Tl+0f5Tl6Pdwr5Z8/H794hRhlHpXAeSl1sxr7n6yanNGI+85jnBb4fMs8jxMGum0R8oHQOSrtx4FFCbJsw6oTMCzwilXvDiwE6GaCTHvp3g676cl+tDdXzWqdVbmp/m9r+lVpp6ZWWsVm5/FyQ3yzLhfLFTrX557j9H7dTdr5mCvRSAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "07",
              "title": "07",
              "src": "/static/f36e665e81a8b9dcc639dc1e1cf6257c/fcda8/07.png",
              "srcSet": ["/static/f36e665e81a8b9dcc639dc1e1cf6257c/12f09/07.png 148w", "/static/f36e665e81a8b9dcc639dc1e1cf6257c/e4a3f/07.png 295w", "/static/f36e665e81a8b9dcc639dc1e1cf6257c/fcda8/07.png 590w", "/static/f36e665e81a8b9dcc639dc1e1cf6257c/efc66/07.png 885w", "/static/f36e665e81a8b9dcc639dc1e1cf6257c/97bfd/07.png 1078w"],
              "sizes": "(max-width: 590px) 100vw, 590px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Give your activity a name, we will leave this page as default for now and click `}<em parentName="p">{`finish`}</em></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Wait for Android Studio to setup our project. Once the project is setup we will see our project like this. We will talk about the project structure in detail in upcoming post.
`}<span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "590px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/bce6e636c42440d74984d1caed8e0e92/5e4a4/08.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "70.94594594594594%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACTElEQVQoz4WRS2sTURTH76L7guhShdKPIvpRulTBki9Q7MYgQo0LK7SKFE1LpbUKtjZQmtbaahpT55VkHslkXpmZO4/cuTczk7mSDgpCrT/O6nB+/DnngMevPy9vHpXe7pfKe8/Wqk9X90rrB6W1w+ebJy+2TpY+1JY+nj5ZPy2ufS+Wj4tvjh6tHBZX9hfffVlc3wcbnw7qtZrA8QLH8SzTqB8zja9d6Wevy8tSoyOzvQ6vdTlV5rVO21AlQxX17rg4XgDfuG7fMocJJcM0IrHtuBLf5wVP0HjVl73IDjEMMNRNzXZc23V0w1B7OvQDpqWAlhHgwImTlFI6Go0wQmo7Yrih5duU0oyOybKMYLJaXp2bmysUCjMzMwsLCxYMgeSSIQoghKNzMI5gPzJN3PchTqIstym1LOv2nVtTU1OTk5MAgCtXr/HtDmjaKCUDRRI9z0uSJAwHoU98Lw0jEiUo+22bpvl+a6NarW5v71QqlVcvlxlBAqzpId+VxKbrwjAMEUL0IgzDUBSFUpqm4wU5luFaHaCgGPnQtvtxnGRZhjG+UNY0rdlsUkrjOKaUNn7U2ZYCZD/UeioZDvODEUIul/Pks7PGWJYgNDU9SZL/yizLUkrzgXr9dPwq2XUVUcpbo/Sfsq7roij+SRZ4jmt3Qc/zXNu5JDk/uKqqs7MPHp4zPz9//95dpqkA73zb/CGXyLZtT09PAwAmJiYAADdvXJd7fdBxXIzQYDBACBGMdd1oKYb9N5ZlBUEgCMLu7m6lUtne2eE51nDCX//G27ldsXh/AAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "08",
                "title": "08",
                "src": "/static/bce6e636c42440d74984d1caed8e0e92/fcda8/08.png",
                "srcSet": ["/static/bce6e636c42440d74984d1caed8e0e92/12f09/08.png 148w", "/static/bce6e636c42440d74984d1caed8e0e92/e4a3f/08.png 295w", "/static/bce6e636c42440d74984d1caed8e0e92/fcda8/08.png 590w", "/static/bce6e636c42440d74984d1caed8e0e92/efc66/08.png 885w", "/static/bce6e636c42440d74984d1caed8e0e92/c83ae/08.png 1180w", "/static/bce6e636c42440d74984d1caed8e0e92/5e4a4/08.png 1403w"],
                "sizes": "(max-width: 590px) 100vw, 590px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Click on the green Play button to build our application. You may select a running device (a phone, a running AVD) or launch an emulator. We will select the AVD that we created in the previous post.
`}<span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "482px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/43e8128a8420c155ce9f709f0eb9f721/37e0d/09.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "92.56756756756756%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAIAAAAf7rriAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABxElEQVQ4y5XTi27TMBQAUH8zv4Im8QlIaPADTGrihK6dxFj9SBwncf3Iw07aAUIMDURQ0rTa1q4dR9HNw7nWdXINEmmK2iVG2e8/fnbdn67r/p7w+/6+cq65+wWUXGZpmmfZUgittJTSGFMfVQ3K1QoYrSmlnKcJSyihGGEhlk3TuEOstX0cro1zgHOe5SOWsJixLEv5FmOMUkoIxhgzxuI4IoQQSqMo0tYC/zHP8/xTPM/7FIbKWhA8FobhJj705BUI4eV0eiB5w/f9yWTiDSCET0YhhNMjyRDCXZH/nfyw8oNTn0g+4tnk8AWCIBg/GBzWB7f8F5jsfhXGmOChCRAmhLA9yR6WJJxz7RxI05TSKGGMEso5b9u2rmtr7RjtfmPXZdWfTdOApRAI4X6+obdzIVgcX3++XtzczC7nCGFTFFoprftgSxN8EW8volJLaS0QeY4QIpv2RYgSGkcxHp4oqQpTjHuo7I+6qqQuk1yXhenL1kqlo6yXZpyPt7v6q6q+Xdlva7te2ca523Xb76qmAe5522Xar2t3dk5fnS1ev6OMsdlsjhEu2haoU6RUZaE/XNA35+j9xyiK6NX8Ci2QrOt/34m2V2m7groAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Run application",
                "title": "Run application",
                "src": "/static/43e8128a8420c155ce9f709f0eb9f721/37e0d/09.png",
                "srcSet": ["/static/43e8128a8420c155ce9f709f0eb9f721/12f09/09.png 148w", "/static/43e8128a8420c155ce9f709f0eb9f721/e4a3f/09.png 295w", "/static/43e8128a8420c155ce9f709f0eb9f721/37e0d/09.png 482w"],
                "sizes": "(max-width: 482px) 100vw, 482px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </li>
    </ol>
    <p>{`Wait for the AVD to boot up. Once it is booted up and the application is installed we will see our application running. Yay!
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/3b85e3dac99b5d2811570739c7f994e3/baaa6/10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "127.70270270270272%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAaCAIAAAA44esqAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACjUlEQVQ4y63U22/SUBzA8YNblmUy0Y1MtzGyRKMmYrbIKG5AoUwHpbRcWoebA4maGDqa7In44juV+T/6tpWkLYVeKDcNPdnFzVkf/OakOc3JJ780bQpeZ/d3suU49c6H0u61tHuNsK7WWk/PrxEP/Jkn8dJq+K0nSG9kKonSl/3qV46rse8/g8Wl+Xm3c2Zm5s7s7Xsu5z2Xc841O3f3YrnnXE8fP3y06vV6FvHEq3LpgGHyKSIVT6RA7gBhSqEX64EIGgmFwuEI+sz3fHFpeWnZc74WFu57VlZWvN6dncRBsbi3t18oFD58/AQax3yjUed5vtFofLOq/yme5+v1erFY3N3dpShqOx7P0W/AycnpD6tms6nruqqq2rVUVdV1XVEUnuePj7+Xy+XAxgZBUuBUOG02BUEQmjcHTwVBqNVqhUKBoqhoFM3RDBBFUT5LuiFRFCVJkmW5Uqn4/X4Mw9BIJJengShKsl1QtlotlmWDwWAsFtvcfElm8kCSpJZdUCqKwrIsgiAxDAttbZHZPJBlWbELyna7fXh4iCAIdo5brVbbLig7nc41rCgdu6BUVfUqbrfbql1Qapp2DXc6ml1Q6rperVZ/w/Dr+XtQGoZxFWuaZtgFZbfbvYp1Xe/aBaVpmpfwpoUNw7QLyl6vx3Hc+eQ0lQVGt9uzC8p+v89xXCAQGOPQFkFmQdc0+3ZBORgMLvB4cg6YZm9gF5TD4fAyHj9zr98f2gXlaDT6r/jnPzQajeDm6OjoDFuviiDSOJ4kSTKVSuFWBEEwDEMQBI7jyWSSoiiapuHR+B+CotFodDw5kwPAcQsAMDExMTU1BawcDsf09PTk5CS8dVrBvc/nwzAsgqKRcGgbp34BcHkNVEFZ7YoAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "10",
            "title": "10",
            "src": "/static/3b85e3dac99b5d2811570739c7f994e3/fcda8/10.png",
            "srcSet": ["/static/3b85e3dac99b5d2811570739c7f994e3/12f09/10.png 148w", "/static/3b85e3dac99b5d2811570739c7f994e3/e4a3f/10.png 295w", "/static/3b85e3dac99b5d2811570739c7f994e3/fcda8/10.png 590w", "/static/3b85e3dac99b5d2811570739c7f994e3/baaa6/10.png 813w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Play around with Android Studio, AVD and the application that you created. Now that you have your first application running. Let’s build something cool!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      